import { createSlice } from "@reduxjs/toolkit";

const activeHelpingStaff= sessionStorage.getItem('activeHelpingStaff') !== null ? 
JSON.parse(sessionStorage.getItem('activeHelpingStaff')) : null

export const initialHelpingStaffForm ={
    mobile_number:"",
    first_name:"",
    last_name:"",
    gender:"",
    email_id:"",
    primary_role:""
}

export const initialManageHelpingStaffForm={
    mobile_number:"",
    first_name:"",
    last_name:"",
    gender:"",
    email_id:"",
    Admin_id:"",
    role:""
}

const initialState={
    activeHelpingStaff:activeHelpingStaff,
    popup:{
     addHelpingStaffPopup:false,
     editHelpingStaffPopup:false,
     deleteHelpingStaffPopup:false,
     verifyHelpingStaffDocumentPopup:false,
    },
    form:{
     helpingStaffForm:initialHelpingStaffForm,
     addHelpingStaffComponent:1,
     editHelpingStaffForm:initialManageHelpingStaffForm,
    },
 }

const helpingStaffSlice=createSlice({
    name:"helpingStaff",
    initialState,
    reducers:{
        // helping staff information
        addActiveHelpingStaff:(state, action)=>{
            state.activeHelpingStaff=action.payload
            sessionStorage.setItem('activeHelpingStaff', 
            JSON.stringify(state.activeHelpingStaff))
            const helpingStaffObject={
                mobile_number:action.payload?.user?.mobile_number,
                first_name:action.payload?.user?.first_name,
                last_name:action.payload?.user?.last_name,
                gender:action.payload.user?.gender,
                email_address:action.payload.user?.email_address,
                helping_staff_id:action.payload.id,
                role:action.payload.role
            }
            state.form.editHelpingStaffForm=helpingStaffObject           
        },

        //helpingStaff popup
        openAddHelpingStaffPopup:(state)=>{
            state.popup.addHelpingStaffPopup=true
        },
        closeAddHelpingStaffPopup:(state)=>{
            state.popup.addHelpingStaffPopup=false
        },
        openEditHelpingStaffPopup:(state)=>{
            state.popup.editHelpingStaffPopup=true
        },
        closeEditHelpingStaffPopup:(state)=>{
            state.popup.editHelpingStaffPopup=false
        },
        openDeleteHelpingStaffPopup:(state)=>{
            state.popup.deleteHelpingStaffPopup=true
        },
        closeDeleteHelpingStaffPopup:(state)=>{
            state.popup.deleteHelpingStaffPopup=false
        },
        openVerifyHelpingStaffDocumentPopup:(state)=>{
            state.popup.verifyHelpingStaffDocumentPopup=true
        },
        closeVerifyHelpingStaffDocumentPopup:(state)=>{
            state.popup.verifyHelpingStaffDocumentPopup=false
        },

        // form reducers
        addHelpingStaffComponent:(state, action)=>{
            state.form.addHelpingStaffComponent=action.payload
        },
        editHelpingStaffForm:(state, action)=>{
            state.form.editHelpingStaffForm=action.payload
        },
        addHelpingStaffForm:(state, action)=>{
            state.form.helpingStaffForm=action.payload
        },
        addHelpingStaffMobileNumber:(state, action)=>{
            state.form.helpingStaffForm.mobile_number=action.payload
        },
    },
})

export const {addActiveHelpingStaff, openAddHelpingStaffPopup,
closeAddHelpingStaffPopup, closeAddRemoveHelpingStaffPopup,
closeAddVerifiedHelpingStaffPopup, addHelpingStaffComponent, addHelpingStaffForm,
addHelpingStaffMobileNumber,openDeleteHelpingStaffPopup, openEditHelpingStaffPopup,
closeDeleteHelpingStaffPopup, closeEditHelpingStaffPopup, openVerifyHelpingStaffDocumentPopup,
closeVerifyHelpingStaffDocumentPopup, editHelpingStaffForm}=helpingStaffSlice.actions

// helping staff state
export const getActiveHelpingStaff=(state)=>state.helpingStaff.activeHelpingStaff

// helping staff popup state
export const getAddHelpingStaffPopup=(state)=>state.helpingStaff.popup.addHelpingStaffPopup
export const getEditHelpingStaffPopup=(state)=>state.helpingStaff.popup.editHelpingStaffPopup
export const getDeleteHelpingStaffPopup=(state)=>state.helpingStaff.popup.deleteHelpingStaffPopup
export const getVerifyHelpingStaffDocumentPopup=(state)=>state.helpingStaff.popup.verifyHelpingStaffDocumentPopup

// helpingStaff form state
export const getHelpingStaffForm=(state)=>state.helpingStaff.form.helpingStaffForm
export const getEditHelpingStaffForm=(state)=>state.helpingStaff.form.editHelpingStaffForm
export const getAddHelpingStaffComponent=(state)=>state.helpingStaff.form.addHelpingStaffComponent

export default  helpingStaffSlice.reducer