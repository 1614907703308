import { combineReducers, configureStore } from '@reduxjs/toolkit'

import preschoolSlice from './preschool/preschool';

import { apiSlice } from 'Redux/api/authApiSlice';
import { muesApiSlice } from 'api/authentication/authentication';
import { authFinancialSlice } from './api/authFinancialSlice';
import { authCurriculumApiSlice } from './api/authCurriculumApiSlice';
import { parentSliceApi } from './api/parentSliceApi';
import sidebarSlice from './sidebar/sidebar';
import classSlice from './class/class';
import teacherSlice from './Team/teacherSlice';
import adminSlice from './Team/adminSlice'
import helpingStaffSlice from './Team/helpingStaffSlice'
import popup from './popup/popup';
import elg from './curriculum/elg';
import annualPlanner from './curriculum/annualPlanner'
import planner from './curriculum/planner';
import feesOverview from './finances/feesOverview';
import accountDelete from './accountDelete/accountDelete';
import boardcast from './boardcast/boardcast';
import event from './event/event';
import chat from './chat/chat';
import userOnboard from './userOnboard/userOnboard';
import student from './class/student';
import academicPlan from './class/academicPlan';
import learningGoal from './class/learningGoal';
import activities from './class/activities';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
    [apiSlice.reducerPath]:apiSlice.reducer,
    [authFinancialSlice.reducerPath]:authFinancialSlice.reducer,
    [authCurriculumApiSlice.reducerPath]:authCurriculumApiSlice.reducer,
    [parentSliceApi.reducerPath]:parentSliceApi.reducer,
    [muesApiSlice.reducerPath]:muesApiSlice.reducer,
    preschool:preschoolSlice,
    sidebar:sidebarSlice,
    class:classSlice,
    teacher:teacherSlice,
    popup:popup,
    admin:adminSlice,
    helpingStaff:helpingStaffSlice,
    elg:elg,
    annualPlanner:annualPlanner,
    planner:planner,
    feesOverview:feesOverview,
    accountDelete:accountDelete,
    boardcast:boardcast,
    event:event,
    chat:chat,
    userOnboard:userOnboard,
    student:student,
    academicPlan:academicPlan,
    learningGoal:learningGoal,
    activities:activities,
})

// Export the function that creates and configures the store
export const createStoreWithPreloadedState = (preloadedState) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(
                apiSlice.middleware,
                authFinancialSlice.middleware,
                authCurriculumApiSlice.middleware,
                parentSliceApi.middleware,
                muesApiSlice.middleware,
            ),
        devTools: true,
        preloadedState,
    });
};

export const store = createStoreWithPreloadedState();
