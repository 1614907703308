import { createSlice } from '@reduxjs/toolkit'

export const planner=createSlice({
    name:"planner",
    initialState: {
        activePlannerClass:null,
        activityInformation:null,
        popup:{
            activityInformationPopup:false
        }
    },
    reducers:{
        //planner information reducer
        addActivePlannerClass:(state, action)=>{
            state.activePlannerClass=action.payload
        },
        addActivityInformation:(state, action)=>{
            state.activityInformation=action.payload
        },

        //planner popup reducer
        openActivityInformationPopup:(state)=>{
            state.popup.activityInformationPopup=true
        },
        closeActivityInformationPopup:(state)=>{
            state.popup.activityInformationPopup=false
        }
    }
})

export const {addActivePlannerClass, openActivityInformationPopup,
closeActivityInformationPopup, addActivityInformation}=planner.actions

//planner information state
export const getActivePlannerClass=(state)=>state.planner.activePlannerClass
export const getActivityInformation=(state)=>state.planner.activityInformation

//planner popup state
export const getActivityInformationPopup=(state)=>state.planner.popup.activityInformationPopup

export default planner.reducer