import { createSlice } from '@reduxjs/toolkit'

export const feesOverview=createSlice({
    name:"feesOverview",
    initialState: {
        popup:{
            addTransactionPopup:false,
            modifyFeesPlanPopup:false,
        },
        form:{
          transactionForm:null,
          modifyFeesPlan:null
        }
    },
    reducers:{
      openAddTransactionPopup:(state)=>{
        state.popup.addTransactionPopup=true
      },
      closeAddTransactionPopup:(state)=>{
        state.popup.addTransactionPopup=false
      },
      openModifyFeesPlanPopup:(state)=>{
        state.popup.modifyFeesPlanPopup=true
      },
      closeModifyFeesPlanPopup:(state)=>{
        state.popup.modifyFeesPlanPopup=false
      },

      //form reducer
      addTransactionForm:(state, action)=>{
        state.form.transactionForm=action.payload
      },
      addModifyFeesPlan:(state, action)=>{
        state.form.modifyFeesPlan=action.payload
      }
    }
})

export const {openAddTransactionPopup, closeAddTransactionPopup,
closeModifyFeesPlanPopup, openModifyFeesPlanPopup, addTransactionForm, 
addModifyFeesPlan}=feesOverview.actions

// popup state
export const getAddTransactionPopup=(state)=>state.feesOverview.popup.addTransactionPopup
export const getModifyFeesPlanPopup=(state)=>state.feesOverview.popup.modifyFeesPlanPopup

//form state
export const getTransactionForm=(state)=>state.feesOverview.form.transactionForm
export const getModifyFeesPlanForm=(state)=>state.feesOverview.form.modifyFeesPlan

export default feesOverview.reducer