import { createSlice } from '@reduxjs/toolkit'

const activeProgram= sessionStorage.getItem('activeProgram') !== null ? 
JSON.parse(sessionStorage.getItem('activeProgram')) : null

export const elg=createSlice({
    name:"elg",
    initialState: {
        activeProgram:activeProgram,
        elgInformation:null,
        popup:{
            addElgFrameworkPopup:false,
            addCustomElgFrameworkPopup:false,
            deleteElgForClassPopup:false
        },
        form:{
            customElgComponent:1,
            elgComponent:1,
            elgForm:{
                framework_name:"",
                description:"",
                private:false,
                stage:""
            },
            primaryGoalForm:{
                primary_goal:"",
                description:"",
            },
            secondaryGoalForm:{
                secondary_goal:"",
                description:"",
            },
            microAtomicGoalForm:{
                name:"",
                description:"",
            },
            atomicGoalForm:{
                name:"",
                description:"",
            },
        }
    },
    reducers:{
        addActiveProgram:(state, action)=>{
            state.activeProgram=action.payload
            sessionStorage.setItem('activeProgram', 
            JSON.stringify(state.activeProgram))
        },
        addElgInformation:(state, action)=>{
            state.elgInformation=action.payload
        },

        //elg popup reducer
        openAddElgFrameworkPopup:(state,action)=>{
            state.popup.addElgFrameworkPopup=true
        },
        closeAddElgFrameworkPopup:(state,action)=>{
            state.popup.addElgFrameworkPopup=false
        },
        openAddCustomElgFrameworkPopup:(state,action)=>{
            state.popup.addCustomElgFrameworkPopup=true
        },
        closeAddCustomElgFrameworkPopup:(state,action)=>{
            state.popup.addCustomElgFrameworkPopup=false
        },
        openDeleteElgForClass:(state,action)=>{
            state.popup.deleteElgForClassPopup=true
        },
        closeDeleteElgForClass:(state,action)=>{
            state.popup.deleteElgForClassPopup=false
        },

        //elg form reducer
        addCustomElgComponent:(state, action)=>{
            state.form.customElgComponent=action.payload
        },
        addElgComponent:(state, action)=>{
            state.form.elgComponent=action.payload
        },
        addElgForm:(state, action)=>{
            state.form.elgForm=action.payload
        },
        addPrimaryGoalForm:(state, action)=>{
            state.form.primaryGoalForm=action.payload
        },
        addSecondaryGoalForm:(state, action)=>{
            state.form.secondaryGoalForm=action.payload
        },
        addMicroAtomicGoalForm:(state, action)=>{
            state.form.microAtomicGoalForm=action.payload
        },
        addAtomicGoalForm:(state, action)=>{
            state.form.atomicGoalForm=action.payload
        },
    }
})

export const {addActiveProgram, closeAddElgFrameworkPopup, openAddElgFrameworkPopup,
openAddCustomElgFrameworkPopup, closeAddCustomElgFrameworkPopup,
closeDeleteElgForClass, openDeleteElgForClass, addCustomElgComponent,
addElgForm, addElgComponent, addElgInformation, addPrimaryGoalForm,
addMicroAtomicGoalForm, addSecondaryGoalForm, addAtomicGoalForm}=elg.actions

//elg information state
export const getActiveProgram=(state)=>state.elg.activeProgram
export const getElgInformation=(state)=>state.elg.elgInformation

// elg popup state
export const getAddElgFrameworkPopup=(state)=>state.elg.popup.addElgFrameworkPopup
export const getAddCustomElgFrameworkPopup=(state)=>state.elg.popup.addCustomElgFrameworkPopup
export const getDeleteElgForClassPopup=(state)=>state.elg.popup.deleteElgForClassPopup

//elg form state
export const getCustomElgComponent=(state)=>state.elg.form.customElgComponent
export const getElgComponent=(state)=>state.elg.form.elgComponent
export const getElgForm=(state)=>state.elg.form.elgForm
export const getPrimaryGoalForm=(state)=>state.elg.form.primaryGoalForm
export const getMicroAtomicGoalForm=(state)=>state.elg.form.microAtomicGoalForm
export const getSecondaryGoalForm=(state)=>state.elg.form.secondaryGoalForm
export const getAtomicGoalForm=(state)=>state.elg.form.atomicGoalForm

export default elg.reducer