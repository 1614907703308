// mues tech server
// export const mainUrl="http://192.168.1.47:8002/" // local server
// export const mainUrl="https://dev.backend.mues.tech/" // development server
// export const mainUrl="https://test.backend.mues.tech/" // testing server
export const mainUrl="https://backend.mues.tech/" // production server

// curriculum server
export const curriculumUrl="http://15.206.151.172:8000/" // development server

// financial server
export const financialUrl="http://192.168.1.47:5000/" // local server

// chat server 
// export const chatServer="ws://192.168.1.47:8003/ws/chat/" // local server
// export const chatServer="wss://dev.messaging.mues.tech/ws/chat/" // development server
export const chatServer="wss://messaging.mues.tech/ws/chat/" // production server
// export const chatServer="wss://test.messaging.mues.tech/ws/chat/" // testing server

