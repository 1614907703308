// react library imports
import { lazy } from 'react';
import { createBrowserRouter} from 'react-router-dom';

// routes imports
import { publicRoutes } from './PublicRoutes';
import { dashboardRoutes } from './PrivateRoutes';
import { principalRoutes } from './PrincipalRoutes';
import { policiesRoutes } from './PoliciesRoutes';
import { userOnboardRoutes } from './UserOnboardRoutes';
import { helpCenterRoutes } from './HelpCenterRoutes';
import { resourceRoutes } from './ResourceRoutes';

// Error pages
const NotFound = lazy(()=>import('Pages/404NotFound/404NotFound'))

export const router = createBrowserRouter([
    ...publicRoutes,
    ...dashboardRoutes,
    ...principalRoutes,
    ...policiesRoutes,
    ...userOnboardRoutes,
    ...helpCenterRoutes,
    ...resourceRoutes,
    {
        path:"*",
        element:<NotFound/>
    }
])
