// react library imports
import { lazy } from 'react';

// Layout imports
const ResourceLayout = lazy(()=>import('Layout/Resource'))

// resource pages imports
const DigitalMarketing = lazy(()=>import('Pages/Resource/DigitalMarketing/DigitalMarketing'))
const MuesFramework = lazy(()=>import('Pages/Resource/MuesFramework/MuesFramework'))
const NEP = lazy(()=>import('Pages/Resource/NEP/NEP'))
const PreschoolGuide = lazy(()=>import('Pages/Resource/PreschoolGuide/PreschoolGuide'))
const WorkSheets = lazy(()=>import('Pages/Resource/WorkSheets/WorkSheets'))

export const resourceRoutes = [
    {
      path: "resources",
      element: <ResourceLayout />,
      children: [
        { path: "mues-framework", element: <MuesFramework /> },
        { path: "preschool-guide", element: <PreschoolGuide /> },
        { path: "digital-marketing", element: <DigitalMarketing /> },
        { path: "worksheets", element: <WorkSheets /> },
        { path: "nep-2003", element: <NEP /> },
      ],
    },
];