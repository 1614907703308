import { createSlice } from "@reduxjs/toolkit";

const activeTeacher= sessionStorage.getItem('activeTeacher') !== null ? 
JSON.parse(sessionStorage.getItem('activeTeacher')) : null

export const teacherForm ={
    mobile_number:"",
    first_name:"",
    last_name:"",
    gender:"",
    email_address:"",
    role:"",
    teacher_id:"",
}
  
const initialState={
   activeTeacher:activeTeacher,
   popup:{
    addNewTeacherPopup:false,
    editTeacherPopup:false,
    removeTeacherPopup:false,
    verifyTeacherDocumentPopup:false,
    makeAdminPopup:false,
    deleteTeacherPopup:false,
   },
   form:{
    teacherForm:teacherForm,
    addTeacherComponent:1,
    editTeacherForm:teacherForm,
   },
   teacherAnalyticInformation:null,
   teacherActivity:null
}

const teacherSlice=createSlice({
    name:"teacher",
    initialState,
    reducers:{
        // teacher information reducer
        addActiveTeacher:(state, action)=>{
            state.activeTeacher=action.payload
            sessionStorage.setItem('activeTeacher', 
            JSON.stringify(state.activeTeacher))
            const teacherObject={
                mobile_number:action.payload?.user?.mobile_number,
                first_name:action.payload?.user?.first_name,
                last_name:action.payload?.user?.last_name,
                gender:action.payload?.user?.gender,
                email_address:action.payload?.user?.email_address ? action.payload?.user?.email_address:"",
                teacher_id:action.payload?.id,
                role:action.payload?.role
            }
            state.form.editTeacherForm=teacherObject
        },
        addTeacherAnalyticInformation:(state, action)=>{
            state.teacherAnalyticInformation=action.payload
        },
        addTeacherActivities:(state, action)=>{
            state.teacherActivity=action.payload
        },

        // popup reducer
        openAddNewTeacherPopup:(state)=>{
            state.popup.addNewTeacherPopup=true
        },
        closeAddNewTeacherPopup:(state)=>{
            state.popup.addNewTeacherPopup=false
        },
        openEditTeacherPopup:(state)=>{
            state.popup.editTeacherPopup=true
        },
        closeEditTeacherPopup:(state)=>{
            state.popup.editTeacherPopup=false
        },
        openRemoveTeacherPopup:(state)=>{
            state.popup.removeTeacherPopup=true
        },
        closeRemoveTeacherPopup:(state)=>{
            state.popup.removeTeacherPopup=false
        },
        openVerifyTeacherDocument:(state)=>{
            state.popup.verifyTeacherDocumentPopup=true
        },
        closeVerifyTeacherDocument:(state)=>{
            state.popup.verifyTeacherDocumentPopup=false
        },
        openMakeAdminPopup:(state)=>{
            state.popup.makeAdminPopup=true
        },
        closeMakeAdminPopup:(state)=>{
            state.popup.makeAdminPopup=false
        },
        openDeleteTeacherPopup:(state)=>{
            state.popup.deleteTeacherPopup=true
        },
        closeDeleteTeacherPopup:(state)=>{
            state.popup.deleteTeacherPopup=false
        },

        // form reducers
        addTeacherComponent:(state, action)=>{
            state.form.addTeacherComponent=action.payload
        },
        addTeacherForm:(state, action)=>{
            state.form.teacherForm=action.payload
        },
        editTeacherForm:(state, action)=>{
            state.form.editTeacherForm=action.payload
        },
        addTeacherMobileNumber:(state, action)=>{
            state.form.teacherForm.mobile_number=action.payload
        },
    }
})

export const {addActiveTeacher, closeAddNewTeacherPopup,
openAddNewTeacherPopup, openEditTeacherPopup,
closeEditTeacherPopup,  closeVerifyTeacherDocument, 
openVerifyTeacherDocument, addTeacherComponent, editTeacherForm, addTeacherForm,
addTeacherMobileNumber,  addTeacherAnalyticInformation, 
addTeacherActivities, openMakeAdminPopup, closeMakeAdminPopup, 
openDeleteTeacherPopup, closeDeleteTeacherPopup}=teacherSlice.actions

// teacher information state
export const getActiveTeacher=(state)=>state.teacher.activeTeacher
export const getTeacherAnalyticInformation=(state)=>state.teacher.teacherAnalyticInformation
export const getTeacherActivityInformation=(state)=>state.teacher.teacherActivity

// teacher popup  state
export const getAddNewTeacherPopup=(state)=>state.teacher.popup.addNewTeacherPopup
export const getEditTeacherPopup=(state)=>state.teacher.popup.editTeacherPopup
export const getVerifyTeacherDocument=(state)=>state.teacher.popup.verifyTeacherDocumentPopup
export const getMakeAdminPopup=(state)=>state.teacher.popup.makeAdminPopup
export const getDeleteTeacherPopup=(state)=>state.teacher.popup.deleteTeacherPopup

// teacher form state
export const getTeacherForm=(state)=>state.teacher.form.teacherForm
export const getEditTeacherForm=(state)=>state.teacher.form.editTeacherForm
export const getAddTeacherComponent=(state)=>state.teacher.form.addTeacherComponent

export default teacherSlice.reducer