// react library imports
import { lazy } from 'react';

// Layout imports
const PrincipalLayout = lazy(()=>import('Layout/Principal'))

// principal pages imports
const Principal = lazy(()=>import('Pages/PreschoolList/PreschoolList'))


export const principalRoutes = [
    {
      path: 'preschools',
      element: <PrincipalLayout />,
      children: [
        { index: true, element: <Principal /> },
      ],
    },
];