import { createSlice } from "@reduxjs/toolkit";

const activeAdmin= sessionStorage.getItem('activeAdmin') !== null ? 
JSON.parse(sessionStorage.getItem('activeAdmin')) : null

export const initialAdminForm={
    mobile_number:"",
    first_name:"",
    last_name:"",
    gender:"",
    email_id:"",
    role:""
}

export const initialManageAdminForm={
    mobile_number:"",
    first_name:"",
    last_name:"",
    gender:"",
    email_id:"",
    Admin_id:"",
    role:""
}

const initialState={
    activeAdmin:activeAdmin,
    popup:{
     addAdminPopup:false,
     editAdminPopup:false,
     verifyAdminDocumentPopup:false,
     deleteAdminPopup:false,
    },
    form:{
     adminForm:initialAdminForm,
     addAdminComponent:1,
     editAdminForm:initialManageAdminForm,
    },
    adminAnalyticInformation:null,
    adminActivity:null
 }

const adminSlice=createSlice({
    name:'admin',
    initialState,
    reducers:{
        // admin information reducer
        addActiveAdmin:(state, action)=>{
            state.activeAdmin=action.payload
            sessionStorage.setItem('activeAdmin', 
            JSON.stringify(state.activeAdmin))
            const adminObject={
                mobile_number:action.payload?.user?.mobile_number,
                first_name:action.payload?.user?.first_name,
                last_name:action.payload?.user?.last_name,
                gender:action.payload?.user?.gender,
                email_address:action.payload?.user?.email_address,
                admin_id:action.payload?.id,
                role:action.payload?.role
            }
            state.form.editAdminForm=adminObject
        },
        addAdminAnalyticInformation:(state, action)=>{
            state.adminAnalyticInformation=action.payload
        },
        addAdminActivities:(state, action)=>{
            state.adminActivity=action.payload
        },

        // popup reducer
        openAddAdminPopup:(state)=>{
            state.popup.addAdminPopup=true
        },
        closeAddAdminPopup:(state)=>{
            state.popup.addAdminPopup=false
        },
        openEditAdminPopup:(state)=>{
            state.popup.editAdminPopup=true
        },
        closeEditAdminPopup:(state)=>{
            state.popup.editAdminPopup=false
        },
        openDeleteAdminPopup:(state)=>{
            state.popup.deleteAdminPopup=true
        },
        closeDeleteAdminPopup:(state)=>{
            state.popup.deleteAdminPopup=false
        },
        openVerifyAdminDocumentPopup:(state)=>{
            state.popup.verifyAdminDocumentPopup=true
        },
        closeVerifyAdminDocumentPopup:(state)=>{
            state.popup.verifyAdminDocumentPopup=false
        },

        // form reducers
        addAdminComponent:(state, action)=>{
            state.form.addAdminComponent=action.payload
        },
        addAdminForm:(state, action)=>{
            state.form.adminForm=action.payload
        },
        editAdminForm:(state, action)=>{
            state.form.editAdminForm=action.payload
        },
        addAdminMobileNumber:(state, action)=>{
            state.form.adminForm.mobile_number=action.payload
        },
    },
  
})
export const {addActiveAdmin, addAdminActivities, addAdminAnalyticInformation, addAdminComponent,
addAdminForm,addAdminMobileNumber,addManageAdminForm, addNewAdminList, openAddAdminPopup, openEditAdminPopup,
openVerifyAdminDocumentPopup, closeEditAdminPopup, openDeleteAdminPopup, closeDeleteAdminPopup,
closeVerifyAdminDocumentPopup, closeAddAdminPopup, editAdminForm}=adminSlice.actions

// admin information state
export const getActiveAdmin=(state)=>state.admin.activeAdmin
export const getAdminAnalyticInformation=(state)=>state.admin.adminAnalyticInformation
export const getAdminActivityInformation=(state)=>state.admin.adminActivity

// admin popup  state
export const getAddAdminPopup=(state)=>state.admin.popup.addAdminPopup
export const getEditAdminPopup=(state)=>state.admin.popup.editAdminPopup
export const getDeleteAdminPopup=(state)=>state.admin.popup.deleteAdminPopup
export const getVerifyAdminDocumentPopup=(state)=>state.admin.popup.verifyAdminDocumentPopup

// admin form state
export const getAdminForm=(state)=>state.admin.form.adminForm
export const getEditAdminForm=(state)=>state.admin.form.editAdminForm
export const getAddAdminComponent=(state)=>state.admin.form.addAdminComponent

export default adminSlice.reducer