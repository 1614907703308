import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setNewUser, logOut } from 'Redux/userOnboard/userOnboard';
import { mainUrl } from 'Config/server';

const userDetails=JSON.parse(localStorage.getItem('userInformation')) 

const baseQuery = fetchBaseQuery({
    baseUrl:mainUrl,
    credentials:'include',
    prepareHeaders: (headers, {getState})=>{
        const token=getState().userOnboard.userInformation?.user?.access_token
        if (token) {
            headers.set("authorization", token)
        }
        return headers
    },
})

const baseQueryWithReAuth=async(args, api, extraOptions)=>{
    let result= await baseQuery(args,api, extraOptions)
    if (result?.error?.status===403) {
        const refreshResult= await baseQuery({
            url:'/get-access-token',
            method:"POST",
            body:{refresh_token:userDetails?.user?.refresh_token}
        }, api, extraOptions)
        if (refreshResult?.data?.id===userDetails?.user?.id) {
            api.dispatch(setNewUser(refreshResult.data))
            result=await baseQuery(args, api, extraOptions)
        }else if (refreshResult.error.status===404) {
            api.dispatch(logOut())
        }
    }
    return result
}

export const muesApiSlice = createApi({
    reducerPath: 'muesApi',
    baseQuery: baseQueryWithReAuth,
    endpoints:builder=>({}),
    fetchFn: async (args, api, extraOptions) => {
        // Pass extraOptions along with args and api to baseQueryWithReAuth
        return baseQueryWithReAuth(args, api, extraOptions);
    },
})
