// react library imports
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// Layout imports
const DashboardLayout = lazy(()=>import('Layout/Dashboard'))

// import from home section
const Home = lazy(()=>import('Pages/Private/Home/Home'))

// import from team section
const Team = lazy(()=>import('Pages/Private/Team/Team'))
const Teacher = lazy(()=>import('Pages/Private/Team/Teacher/Teacher'))
const Admin = lazy(()=>import('Pages/Private/Team/Admin/Admin'))
const HelpingStaff = lazy(()=>import('Pages/Private/Team/HelpingStaff/HelpingStaff'))
const LeaderBoard = lazy(()=>import('Pages/Private/Team/LeaderBoard/LeaderBoard'))

// import from classes section
const Classes = lazy(()=>import('Pages/Private/Classes/Classes'))
const BulkUpload =lazy(()=>import('Pages/Private/Classes/Component/BulkUpload/BulkUpload'))
const KidVerification =lazy(()=>import('Pages/Private/Classes/Component/KidVerification/KidVerification'))
const BulkUploadTerm =lazy(()=>import('Pages/Private/Classes/Component/BulkUploadTerm/BulkUploadTerm'))
const BulkUploadTopic =lazy(()=>import('Pages/Private/Classes/Component/BulkUploadTopic/BulkUploadTopic'))
const BulkUploadSubtopic =lazy(()=>import('Pages/Private/Classes/Component/BulkUploadSubtopic/BulkUploadSubtopic'))
const KidInformation =lazy(()=>import('Pages/Private/Classes/Component/KidInformation/KidInformation'))

// import from inventory section
const Inventory = lazy(()=>import('Pages/Private/Inventory/Inventory'))
const AddInventory = lazy(()=>import('Pages/Private/Inventory/AddInventory/AddInventory'))
const Allocation = lazy(()=>import('Pages/Private/Inventory/Allocation/Allocation'))
const InventoryOverview = lazy(()=>import('Pages/Private/Inventory/InventoryOverview/InventoryOverview'))

// import from finances section
const Finances = lazy(()=>import('Pages/Private/Finances/Finances'))
const AddTransaction = lazy(()=>import('Pages/Private/Finances/AddTransaction/AddTransaction'))
const Analysis = lazy(()=>import('Pages/Private/Finances/Analysis/Analysis'))
const FeesOverview = lazy(()=>import('Pages/Private/Finances/FeesOverview/FeesOverview'))

// import from security section
const Security = lazy(()=>import('Pages/Private/Security/Security'))

// import from marketing section
const Marketing = lazy(()=>import('Pages/Private/Marketing/Marketing'))

// import from dashboard topbar section
const Event = lazy(()=>import('Pages/Private/Event/Event'))
const Broadcast = lazy(()=>import('Pages/Private/Broadcast/Broadcast'))
// const ChatPage = lazy(()=>import('Pages/Private/Chat/Chat'))
const Notification =lazy(()=>import('Pages/Private/Notification/Notification'))
const Setting =lazy(()=>import('Pages/Private/Setting/Setting'))

// import from sidebar footer section
const MyPlan = lazy(()=>import('Pages/Private/MyPlan/MyPlan'))
const MyProfile = lazy(()=>import('Pages/Private/MyProfile/MyProfile'))
const AccountAndPayments = lazy(()=>import('Pages/Private/AccountAndPayments/AccountAndPayments'))

const ClassLayout=()=>{
    return(
        <Outlet/>
    )
}

export const dashboardRoutes = [
    {
      path: "dashboard/",
      element: <DashboardLayout />,
      children: [
        { index: true, element: <Home /> },
        {
          path: "team/",
          element: <Team />,
          children: [
            { path: 'teacher', element: <Teacher /> },
            { path: 'admin', element: <Admin /> },
            { path: 'helping-staff', element: <HelpingStaff /> },
            { path: 'leader-board', element: <LeaderBoard /> },
          ],
        },
        {
          path: "classes",
          element: <ClassLayout />,
          children: [
            { path: "add-multiple-students", element: <BulkUpload /> },
            { path: "kid-verification", element: <KidVerification /> },
            { path: "create-term", element: <BulkUploadTerm/> },
            { path: "create-topic", element: <BulkUploadTopic/> },
            { path: "create-subtopic", element: <BulkUploadSubtopic/> },
            { path: "kid", element: <KidInformation/> },
            { path: ":id", element: <Classes /> },
          ],
        },
        {
          path: "inventory/",
          element: <Inventory />,
          children: [
            { path: "inventory-overview", element: <InventoryOverview /> },
            { path: "allocation", element: <Allocation /> },
            { path: "add-inventory", element: <AddInventory /> },
          ],
        },
        {
          path: "finances",
          element: <Finances />,
          children: [
            { path: "fees-overview", element: <FeesOverview /> },
            { path: "analysis", element: <Analysis /> },
            { path: "add-transaction", element: <AddTransaction /> },
          ],
        },
        { path: "security", element: <Security /> },
        { path: "marketing", element: <Marketing /> },
        { path: "event", element: <Event /> },
        { path: "broadcast", element: <Broadcast /> },
        // { path: "chat", element: <ChatPage /> },
        { path: "notification", element: <Notification /> },
        { path: "setting", element: <Setting /> },
        { path: "plan", element: <MyPlan /> },
        { path: "profile", element: <MyProfile /> },
        { path: "account-and-payments", element: <AccountAndPayments /> },
      ],
    },
  ];
  