import { createSlice } from "@reduxjs/toolkit";


// initial state for preschool form
export const initialBasicInformation={
    name:"",
    legal_entity_name:"",
    type_of_preschool:""
}

export const initialDirectorInformation={
    first_name:"",
    last_name:"",
    email_address:"",
    mobile_number:""
}

export const initialPreschoolAddress={
    street_name:"",
    city:"",
    state:"",
    pincode:"",
    google_map_link:""
}

export const initialUploadLogo = ""

// save state into the storage
const basicInformation=sessionStorage.getItem('basicInformation') !== null ? 
JSON.parse(sessionStorage.getItem('basicInformation')) : initialBasicInformation
const directorInformation=sessionStorage.getItem('directorInformation') !== null ? 
JSON.parse(sessionStorage.getItem('directorInformation')) : initialDirectorInformation
const preschoolAddress=sessionStorage.getItem('preschoolAddress') !== null ? 
JSON.parse(sessionStorage.getItem('preschoolAddress')) : initialPreschoolAddress
const uploadPreschoolLogo=sessionStorage.getItem('uploadPreschoolLogo') !== null ? 
JSON.parse(sessionStorage.getItem('uploadPreschoolLogo')) : initialUploadLogo
const userDetails=localStorage.getItem('userInformation') !== null ? 
JSON.parse(localStorage.getItem('userInformation')) : null

const userOnboard = createSlice({
    name:"userOnboard",
    initialState:{
       basicInformation:basicInformation,
       directorInformation:directorInformation,
       preschoolAddress:preschoolAddress,
       uploadPreschoolLogo:uploadPreschoolLogo,
       userInformation:userDetails,
       editUserForm:{
        first_name:"",
        last_name:"",
        mobile_number:"",
        date_of_birth:"",
        email_address:""
       },
       popup:{
            rewardPopup:false,
            uploadLogo:false,
            deleteUserInformationPopup:false
       }
    },
    reducers:{
        // user onboard form dispatch
        addBasicInformation:(state, action)=>{
            state.basicInformation=action.payload
            sessionStorage.setItem('basicInformation', 
            JSON.stringify(state.basicInformation))
        },
        addDirectorInformation:(state, action)=>{
            state.directorInformation=action.payload
            sessionStorage.setItem('directorInformation', 
            JSON.stringify(state.directorInformation))
        },
        addPreschoolAddress:(state, action)=>{
            state.preschoolAddress=action.payload
            sessionStorage.setItem('preschoolAddress', 
            JSON.stringify(state.preschoolAddress))
        },
        addUploadPreschoolLogo:(state, action)=>{
            state.uploadPreschoolLogo=action.payload
            sessionStorage.setItem('uploadPreschoolLogo', 
            JSON.stringify(state.uploadPreschoolLogo))
        },
        addAddress:(state, action)=>{
            state.preschoolAddress.city=action.payload.city
            state.preschoolAddress.state=action.payload.state
        },
        editUserForm:(state, action)=>{
            state.editUserForm=action.payload
        },

        // popup dispatch
        openRewardPopup:(state)=>{
            state.popup.rewardPopup=true
        },
        closeRewardPopup:(state)=>{
            state.popup.rewardPopup=false
        },
        openUploadLogoPopup:(state)=>{
            state.popup.uploadLogo=true
        },
        closeUploadLogoPopup:(state)=>{
            state.popup.uploadLogo=false
        },
        openDeleteUserInformation:(state)=>{
            state.popup.deleteUserInformationPopup=true
        },
        closeDeleteUserInformation:(state)=>{
            state.popup.deleteUserInformationPopup=false
        },

        // authentication dispatch action
        setCredentials:(state, action)=>{
            state.userInformation=action.payload
            localStorage.setItem('userInformation', 
            JSON.stringify(state.userInformation))
            state.directorInformation.mobile_number=action.payload?.user?.mobile_number
            sessionStorage.setItem('directorInformation', 
            JSON.stringify(state.directorInformation))
        },
        setNewUser:(state, action)=>{
            state.userInformation.user=action.payload
            localStorage.setItem('userInformation', 
            JSON.stringify(state.userInformation))
        },
        logOut:(state)=>{
            state.userInformation=null
        },
    }
})

export const {addBasicInformation, addDirectorInformation, 
addPreschoolAddress, addUploadPreschoolLogo, openRewardPopup, 
openUploadLogoPopup, closeRewardPopup, closeUploadLogoPopup,
setCredentials, addAddress, logOut, setNewUser, editUserForm,
openDeleteUserInformation, closeDeleteUserInformation}=userOnboard.actions

export default userOnboard.reducer

// form state
export const getPreschoolBasicInformation=(state)=>state.userOnboard.basicInformation
export const getPreschoolDirectorInformation=(state)=>state.userOnboard.directorInformation
export const getPreschoolPreschoolAddressInformation=(state)=>state.userOnboard.preschoolAddress
export const getPreschoolUploadLogo=(state)=>state.userOnboard.uploadPreschoolLogo
export const getPreschoolAddress=(state)=>state.userOnboard.preschoolAddress
export const getEditUserForm=(state)=>state.userOnboard.editUserForm

// popup state
export const getRewardPopup=(state)=>state.userOnboard.popup.rewardPopup
export const getUploadLogoPopup=(state)=>state.userOnboard.popup.uploadLogo
export const getDeleteUserInformationPopup=(state)=>state.userOnboard.popup.deleteUserInformationPopup

// authentication state
export const getUserInformation=(state)=>state.userOnboard.userInformation