import { createSlice } from "@reduxjs/toolkit";

const navigationId= sessionStorage.getItem('navigationId') !== null ? 
JSON.parse(sessionStorage.getItem('navigationId')) : null

const sidebarSlice = createSlice({
    name:"sidebar",
    initialState:{
        navigationId:navigationId,
        value:false,
        logout:false,
        fund:false,
    },
    reducers:{
        addNavigationId:(state, action)=>{
            state.navigationId=action.payload
            sessionStorage.setItem('navigationId', 
            JSON.stringify(state.navigationId))
        },

        //sidebar popup
        openSidebar:state=>{
            state.value=true
        },
        closeSidebar:state=>{
            state.value=false
        },
        openLogOut:state=>{
            state.logout=true
        },
        closeLogOut:state=>{
            state.logout=false
        },
        openFund:state=>{
            state.fund=true
        },
        closeFund:state=>{
            state.fund=false
        },
    }
})

export const {addNavigationId, openSidebar, closeSidebar, openLogOut,
closeLogOut, openFund, closeFund}=sidebarSlice.actions

export const getNavigationId=(state)=>state.sidebar.navigationId

//side bar popup state
export const getSidebar=(state)=>state.sidebar.value
export const getLogOut=(state)=>state.sidebar.logout
export const getFund=(state)=>state.sidebar.fund


export default sidebarSlice.reducer

