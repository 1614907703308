import { createSlice } from "@reduxjs/toolkit";


const activeStudent= sessionStorage.getItem('activeStudent') !== null ? 
JSON.parse(sessionStorage.getItem('activeStudent')) : null

const studentSlice = createSlice({
    name:"studentSlice",
    initialState:{
        popup:{
            addNewStudentPopup:false,
            documentPopup:false,
            changeRequestPopup:false,
            statusPopup:false
        },
        form:{
            addNewStudentForm:{
                first_name:"",
                last_name:"",
                gender:"",
                date_of_birth:"",
                paid_package:[],
                free_package:[],
                kid_id:""
            },
        },
        other:{
            addNewStudentComponent:1,
            kidInformation:null,
            activeStudent:activeStudent,
            documentInfo:null,
            field_name:null,
            document:null,
            statusName:null,
            skillMap:null,
            
        }
    },
    reducers:{
        // form reducer action
        addNewStudentForm:(state, action)=>{
            state.form.addNewStudentForm=action.payload
        },

        // popup reducer action
        openAddNewStudentPopup:(state)=>{
            state.popup.addNewStudentPopup=true
        },
        closeAddNewStudentPopup:(state)=>{
            state.popup.addNewStudentPopup=false
        },
        openDocumentPopup:(state, action)=>{
            state.popup.documentPopup=true
            state.other.documentInfo=action.payload
        },
        closeDocumentPopup:(state)=>{
            state.popup.documentPopup=false
            state.other.documentInfo=null
        },
        openChangeRequestPopup:(state, action)=>{
            state.popup.changeRequestPopup=true
            state.other.documentInfo=action.payload
        },
        closeChangeRequestPopup:(state)=>{
            state.popup.changeRequestPopup=false
            state.other.documentInfo=null
        },
        openStatusPopup:(state,action)=>{
            state.popup.statusPopup=true
            state.other.statusName=action.payload
        },
        closeStatusPopup:(state)=>{
            state.popup.statusPopup=false
            state.other.statusName=null
        },

        // other reducer action
        addNewStudentComponent:(state, action)=>{
            state.other.addNewStudentComponent=action.payload
        },
        addKidInformation:(state, action)=>{
            state.other.kidInformation=action.payload
        },
        addActiveStudent:(state, action)=>{
            state.other.activeStudent=action.payload
            sessionStorage.setItem('activeStudent', 
            JSON.stringify(state.other.activeStudent))
        },
        addFieldName:(state, action)=>{
            state.other.field_name=action.payload
        },
        addDocument:(state, action)=>{
            state.other.document=action.payload
        },
        addSkillMap:(state, action)=>{
            state.other.skillMap=action.payload
        }
    }
})
export default studentSlice.reducer

export const {addNewStudentForm, openAddNewStudentPopup, openChangeRequestPopup,
closeAddNewStudentPopup, addNewStudentComponent, addKidInformation,
openDocumentPopup, closeChangeRequestPopup, closeDocumentPopup, 
addActiveStudent, addFieldName, addDocument, openStatusPopup, closeStatusPopup,
addSkillMap}=studentSlice.actions

// get form state
export const getAddNewStudentForm = (state) => state.student.form.addNewStudentForm

// get popup state
export const getAddNewStudentPopup = (state) => state.student.popup.addNewStudentPopup
export const getDocumentPopup=(state)=>state.student.popup.documentPopup
export const getChangeRequestPopup=(state)=>state.student.popup.changeRequestPopup
export const getStatusPopup=(state)=>state.student.popup.statusPopup

// get other state
export const getAddNewStudentComponent = (state) => state.student.other.addNewStudentComponent
export const getKidInformation = (state) => state.student.other.kidInformation
export const getActiveStudent = (state) => state.student.other.activeStudent
export const getDocumentInfo = (state) => state.student.other.documentInfo
export const getFieldName = (state) => state.student.other.field_name
export const getDocument = (state) => state.student.other.document
export const getStatusName = (state) => state.student.other.statusName
export const getSkillMap = (state) => state.student.other.skillMap