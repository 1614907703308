import { createSlice } from "@reduxjs/toolkit";

const chat = createSlice({
    name:"chat",
    initialState:{
        latestMessage:null,
        chatStaffList:[],
        chatDetails:null,
        activeChat:null,
        chatGroup:null,
        memberList:[],
        readMessageList:[]
    },
    reducers:{
       addLatestMessage:(state, action)=>{
            state.latestMessage=action.payload
            const isPresentLatestMessage = state.chatDetails?.find(chat=>chat?.id===action?.payload.id)
            if (action.payload?.chat_group_id===state.chatGroup?.id  && !isPresentLatestMessage) {
                state.chatDetails?.push(action.payload)
            }
       },
       addStaffList:(state, action)=>{
        state.chatStaffList=action.payload
       },
       addChatStaffDetails:(state, action)=>{
        state.chatDetails=action.payload
       },
       addActiveChat:(state, action)=>{
        state.activeChat=action.payload
       },
       addOnlineUser:(state, action)=>{
            const newStaff = state.chatStaffList?.map(staff => {
                const isOnline = action.payload.some(id => id === staff.user_id);
                return {
                    ...staff,
                    online: isOnline,
                };
            });
            state.chatStaffList = newStaff;
       },
       addChatGroup:(state, action)=>{
            state.chatGroup=action.payload
       },
       addMemberList:(state, action)=>{
            state.memberList=action.payload
       },
       addReadMessageList:(state, action)=>{
            const isReadMessageList = state.readMessageList?.find(chat=>chat?.id===action?.payload.id)            
            if (!isReadMessageList) {
                state.readMessageList?.push(action.payload)
            }
       }
    }
})

export const {addLatestMessage, addChatStaffDetails, addMemberList,
addStaffList, addActiveChat, addOnlineUser, addChatGroup, addReadMessageList}=chat.actions

export default chat.reducer

// get chat details
export const getLatestMessage=(state)=>state.chat.latestMessage
export const getChatStaffList=(state)=>state.chat.chatStaffList
export const getChatDetails=(state)=>state.chat.chatDetails
export const getActiveChat=(state)=>state.chat.activeChat
export const getChatGroup=(state)=>state.chat.chatGroup
export const getMemberList=(state)=>state.chat.memberList
export const getReadMessageList=(state)=>state.chat.readMessageList

