import { createSlice } from "@reduxjs/toolkit";

const activeLevelOne= sessionStorage.getItem('activeLevelOne') !== null ? 
JSON.parse(sessionStorage.getItem('activeLevelOne')) : null

// initial form
export const initialElgFrameworkForm={
    title:"",
    tags:[],
    description:"",
    private:false,
    level_name:"",
    level_description:""
}

const learningGoalSlice = createSlice({
    name:"learningGoalSlice",
    initialState:{
        popup:{
            addNewElgPopup:false,
            editElgPopup:false,
            deleteElgPopup:false,
            addNewGoalPopup:false,
            namingPopup:false,
            editGoalPopup:false,
            deleteGoalPopup:false,
        },
        form:{
            addNewElgForm:initialElgFrameworkForm,
            editElgForm:initialElgFrameworkForm,
            goalForm:{
                title:"",
                tags:[],
                description:"",
                icon:"",
                skill:""
            },
            editGoalForm:{
                title:"",
                tags:[],
                skills:"",
                description:"",
                icon:""
            },
            namingForm:{
                name:"",
                description:""
            }
        },
        other:{
            rootId:"",
            activeLevelOne:activeLevelOne,
            elgInformation:null
        }
    },
    reducers:{
        // popup reducer action
        openAddNewElgPopup:(state)=>{
            state.popup.addNewElgPopup=true
        },
        closeAddNewElgPopup:(state)=>{
            state.popup.addNewElgPopup=false
        },
        openEditElgPopup:(state, action)=>{
            state.popup.editElgPopup=true
            state.form.editElgForm=action.payload
        },
        closeEditElgPopup:(state)=>{
            state.popup.editElgPopup=false
            state.form.editElgForm=initialElgFrameworkForm
        },
        openDeleteElgPopup:(state)=>{
            state.popup.deleteElgPopup=true
        },
        closeDeleteElgPopup:(state)=>{
            state.popup.deleteElgPopup=false
        },
        openAddNewGoalPopup:(state, action)=>{
            state.popup.addNewGoalPopup=true
            state.other.rootId=action.payload
        },
        closeAddNewGoalPopup:(state)=>{
            state.popup.addNewGoalPopup=false
            state.other.rootId=""
        },
        openNamingPopup:(state, action)=>{
            state.popup.namingPopup=true
            state.other.rootId=action.payload
        },
        closeNamingPopup:(state)=>{
            state.popup.namingPopup=false
            state.other.rootId=""
        },
        openEditGoalPopup:(state, action)=>{
            state.popup.editGoalPopup=true
            state.other.rootId=action.payload
        },
        closeEditGoalPopup:(state)=>{
            state.popup.editGoalPopup=false
            state.other.rootId=""
        },
        openDeleteGoalPopup:(state)=>{
            state.popup.deleteGoalPopup=true
        },
        closeDeleteGoalPopup:(state)=>{
            state.popup.deleteGoalPopup=false
        },
        

        // form dispatch action
        addNewElgForm:(state, action)=>{
            state.form.addNewElgForm=action.payload
        },
        editElgForm:(state, action)=>{
            state.form.editElgForm=action.payload
        },
        addGoalForm:(state, action)=>{
            state.form.goalForm=action.payload
        },
        addEditGoalForm:(state, action)=>{
            state.form.editGoalForm=action.payload
        },
        addNamingForm:(state, action)=>{
            state.form.namingForm=action.payload
        },

        // other dispatch action
        addActiveLevelOne:(state, action)=>{
            state.other.activeLevelOne=action.payload
            sessionStorage.setItem('activeLevelOne', 
            JSON.stringify(state.other.activeLevelOne))
        },
        addElgInformation:(state, action)=>{
            state.other.elgInformation=action.payload
        },
    }
})

export default learningGoalSlice.reducer

export const { addGoalForm, openAddNewElgPopup,
    openAddNewGoalPopup, closeAddNewElgPopup, closeAddNewGoalPopup, addActiveLevelOne,
    openNamingPopup,closeNamingPopup, addNamingForm, openEditGoalPopup, closeEditGoalPopup,
    addEditGoalForm, addElgInformation,
    openEditElgPopup,closeEditElgPopup, addNewElgForm, editElgForm, openDeleteElgPopup,
    closeDeleteElgPopup, openDeleteGoalPopup, closeDeleteGoalPopup
}=learningGoalSlice.actions

// get popup state
export const getAddNewElgPopup = (state) => state.learningGoal.popup.addNewElgPopup
export const getAddNewGoalPopup = (state) => state.learningGoal.popup.addNewGoalPopup
export const getNamingPopup = (state) => state.learningGoal.popup.namingPopup
export const getEditGoalPopup = (state) => state.learningGoal.popup.editGoalPopup
export const getDeleteGoalPopup = (state) => state.learningGoal.popup.deleteGoalPopup
export const getDeleteElgPopup = (state) => state.learningGoal.popup.deleteElgPopup
export const getEditElgPopup = (state) => state.learningGoal.popup.editElgPopup

// get form state
export const getAddNewElgForm = (state) => state.learningGoal.form.addNewElgForm
export const getGoalForm = (state) => state.learningGoal.form.goalForm
export const getNamingForm = (state) => state.learningGoal.form.namingForm
export const getEditGoalForm = (state) => state.learningGoal.form.editGoalForm
export const getEditElgForm = (state) => state.learningGoal.form.editElgForm

// get other form state
export const getRootId = (state) =>state.learningGoal.other.rootId
export const getActiveLevelOne = (state) =>state.learningGoal.other.activeLevelOne
export const getElgInformation = (state) =>state.learningGoal.other.elgInformation