// react library imports
import { lazy } from 'react';

// Layout imports
const UserOnboardLayout = lazy(()=>import('Layout/UserOnboard'))
const PublicLayout = lazy(()=>import('Layout/Public'))

// registration pages
const JoinUs = lazy(()=>import('Pages/UserOnboard/JoinUs/JoinUs'))
const PreschoolBasicInformation = lazy(()=>import('Pages/UserOnboard/PreschoolBasicInformation/PreschoolBasicInformation'))
const DirectorDetails = lazy(()=>import('Pages/UserOnboard/DirectorDetails/DirectorDetails'))
const PreschoolAddress = lazy(()=>import('Pages/UserOnboard/PreschoolAddress/PreschoolAddress'))
const UploadPreschoolLogo = lazy(()=>import('Pages/UserOnboard/UploadPreschoolLogo/UploadPreschoolLogo'))
const Preview = lazy(()=>import('Pages/UserOnboard/Preview/Preview'))
const Funds = lazy(()=>import('Pages/UserOnboard/Funds/Funds'))

// login pages
const Login = lazy(()=>import('Pages/UserOnboard/Login/Login'))
const Otp = lazy(()=>import('Pages/UserOnboard/Otp/Otp'))

export const userOnboardRoutes = [
    {
      path: "login/",
      element: <PublicLayout />,
      children: [
        { index: true, element: <Login /> },
      ],
    },
    {
      path: "register/",
      element: <UserOnboardLayout />,
      children: [
        { index: true, element: <JoinUs /> },
        { path: "step-1", element: <PreschoolBasicInformation /> },
        { path: "step-2", element: <DirectorDetails /> },
        { path: "step-3", element: <PreschoolAddress /> },
        { path: "step-4", element: <UploadPreschoolLogo /> },
        { path: "step-5", element: <Preview /> },
        { path: "congratulations", element: <Funds /> },
      ],
    },
    {
      path: "verify-otp/",
      element: <UserOnboardLayout />,
      children: [
        { index: true, element: <Otp /> },
      ],
    },
  ];
  