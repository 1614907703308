import { createSlice } from "@reduxjs/toolkit";
import Moment from "moment";

export const holidayForm={
    date:Moment(new Date()).format("YYYY-MM-DD"),
    date_timing:Moment(new Date()).format("YYYY-MM-DD hh:mm A"),
    timing:"",
    title:"",
    description:"",
    icon:"",
    reminder_stream:""
}

export const eventForm={
    date:Moment(new Date()).format("YYYY-MM-DD"),
    start_timing:Moment(new Date()).format("YYYY-MM-DD HH:mm"),
    end_timing:Moment(new Date()).format("YYYY-MM-DD HH:mm"),
    title:"",
    description:"",
    icon:"",
    reminder_stream:"",
    invitees:"",
    teacher:true,
    parent:false,
    classes:[]
}

const event = createSlice({
    name:"event",
    initialState:{
        popup:{
            holiday:false,
            event:false,
            editEvent:false,
            editHoliday:false,
            deleteEvent:false
        },
        form:{
            holidayForm:holidayForm,
            eventForm:eventForm,
            editEventForm:eventForm,
            editHolidayForm:holidayForm,
            deleteEventForm:null
        }
    },
    reducers:{
       openHoliday:(state)=>{
        state.popup.holiday=true
       },
       closeHoliday:(state)=>{
        state.popup.holiday=false
       },
       openEvent:(state)=>{
        state.popup.event=true
       },
       closeEvent:(state)=>{
        state.popup.event=false
       },
       openEditEvent:(state, action)=>{
        state.popup.editEvent=true
        state.form.editEventForm=action.payload
       },
       closeEditEvent:(state)=>{
        state.popup.editEvent=false
        state.form.editEventForm=eventForm
       },
       openEditHoliday:(state, action)=>{
        state.popup.editHoliday=true
        state.form.editHolidayForm=action.payload
       },
       closeEditHoliday:(state)=>{
        state.popup.editHoliday=false
        state.form.editHolidayForm=holidayForm
       },
       openDeleteEvent:(state, action)=>{
        state.popup.deleteEvent=true
        state.form.deleteEventForm=action.payload
       },
       closeDeleteEvent:(state)=>{
        state.popup.deleteEvent=false
        state.form.deleteEventForm=null
       },

       createHolidayForm:(state, action)=>{
        state.form.holidayForm=action.payload
       },
       createEventForm:(state, action)=>{
        state.form.eventForm=action.payload
       },
       editEventForm:(state, action)=>{
        state.form.editEventForm=action.payload
       },
       editHolidayForm:(state, action)=>{
        state.form.editHolidayForm=action.payload
       },
    }
})

export const {openEvent, closeEvent, createHolidayForm,
createEventForm, closeHoliday, openHoliday, editEventForm,
openEditEvent, closeEditEvent, editHolidayForm, openEditHoliday,
closeEditHoliday, openDeleteEvent, closeDeleteEvent}=event.actions

export default event.reducer

// popup state
export const getEvent=state=>state.event.popup.event
export const getHoliday=state=>state.event.popup.holiday
export const getEditEvent=state=>state.event.popup.editEvent
export const getEditHoliday=state=>state.event.popup.editHoliday
export const getDeleteEvent=state=>state.event.popup.deleteEvent

// form state
export const getHolidayForm=state=>state.event.form.holidayForm
export const getEventForm=state=>state.event.form.eventForm
export const getEditEventForm=state=>state.event.form.editEventForm
export const getEditHolidayForm=state=>state.event.form.editHolidayForm
export const getDeleteEventForm=state=>state.event.form.deleteEventForm

