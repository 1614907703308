import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials, logOut } from '../userOnboard/userOnboard';
import { curriculumUrl } from 'Config/server';

const userDetails=JSON.parse(localStorage.getItem('userInformation')) 

const baseQuery = fetchBaseQuery({
    baseUrl:curriculumUrl,
    credentials:'include',
    prepareHeaders: (headers, {getState})=>{
        const token=getState().userOnboard.userInformation?.token
        if (token) {
            headers.set("authorization", token)
        }
        return headers
    },
})

const baseQueryWithReAuth=async(args, api, extraOptions)=>{
    let result= await baseQuery(args,api, extraOptions)
    if (result?.error?.status===403) {
        const refreshResult= await baseQuery({
            url:'/get-access-token',
            method:"POST",
            body:{refresh_token:userDetails?.refresh_token}
        }, api, extraOptions)
        if (refreshResult?.data?.id===userDetails?.id) {
            api.dispatch(setCredentials(refreshResult.data))
            result=  await baseQuery(args, api, extraOptions)
        }else if (refreshResult.error.status===404){
            api.dispatch(logOut())
        }
    }
    return result
}

export const authCurriculumApiSlice=createApi({
    reducerPath: 'apiThree',
    baseQuery: baseQueryWithReAuth,
    endpoints:builder=>({})
})