import { createSlice } from "@reduxjs/toolkit";

const activeTerm= sessionStorage.getItem('activeTerm') !== null ? 
JSON.parse(sessionStorage.getItem('activeTerm')) : null

// academic year form
export const initialAcademicForm={
    title:"",
    start_date:"",
    end_date:""
}

const academyPlanSlice = createSlice({
    name:"academyPlanSlice",
    initialState:{
        popup:{
            academicYearPopup:false,
            editAcademicYearPopup:false,
            deleteAcademicYearPlanPopup:false,
            termPopup:false,
            topicPopup:false,
            editTopicPopup:false,
            subtopicPopup:false,
            deleteTopicPopup:false,
            editTermPopup:false,
            deleteTermPopup:false,
        },
        form:{
            academicYearForm:initialAcademicForm,
            editAcademicYearForm:initialAcademicForm,
            termForm:{
                title:"",
                description:"",
                start_date:"",
                end_date:"",
                icon:""
            },
            editTermForm:{
                title:"",
                description:"",
                start_date:"",
                end_date:"",
                icon:""
            },
            topicForm:{
                standard_topic_id:"",
                title:"",
                description:"",
                icon:""
            },
            editTopicForm:{
                standard_topic_id:"",
                title:"",
                description:"",
                icon:""
            },
        },
        other:{
            activeTerm:activeTerm,
            activeTopic:null,
            rootId:"",
            termList:[],
            academicPlan:null
        }
    },
    reducers:{
        // popup reducer action
        openAcademicYearPopup:(state)=>{
            state.popup.academicYearPopup=true
        },
        closeAcademicYearPopup:(state)=>{
            state.popup.academicYearPopup=false
        },
        openTermPopup:(state)=>{
            state.popup.termPopup=true
        },
        closeTermPopup:(state)=>{
            state.popup.termPopup=false
        },
        openEditTermPopup:(state)=>{
            state.popup.editTermPopup=true
        },
        closeEditTermPopup:(state)=>{
            state.popup.editTermPopup=false
        },
        openDeleteTermPopup:(state)=>{
            state.popup.deleteTermPopup=true
        },
        closeDeleteTermPopup:(state)=>{
            state.popup.deleteTermPopup=false
        },
        openTopicPopup:(state, action)=>{
            state.popup.topicPopup=true            
            state.other.rootId=action.payload
        },
        closeTopicPopup:(state)=>{
            state.popup.topicPopup=false
            state.other.rootId=null
        },
        openEditTopicPopup:(state, action)=>{
            state.popup.editTopicPopup=true
            state.other.rootId=action.payload
        },
        closeEditTopicPopup:(state)=>{
            state.popup.editTopicPopup=false
            state.other.rootId=null
        },
        openDeleteTopicPopup:(state, action)=>{
            state.popup.deleteTopicPopup=true
            state.other.rootId=action.payload
        },
        closeDeleteTopicPopup:(state)=>{
            state.popup.deleteTopicPopup=false
            state.other.rootId=null
        },
        openSubtopicPopup:(state, action)=>{
            state.popup.subtopicPopup=true
            state.other.rootId=action.payload
        },
        closeSubtopicPopup:(state)=>{
            state.popup.subtopicPopup=false
            state.other.rootId=null
        },
        openEditAcademicYearPopup:(state, action)=>{
            state.popup.editAcademicYearPopup=true
            state.form.editAcademicYearForm=action.payload
        },
        closeEditAcademicYearPopup:(state)=>{
            state.popup.editAcademicYearPopup=false
            state.form.editAcademicYearForm=initialAcademicForm
        },
        openDeleteAcademicYearPopup:(state)=>{
            state.popup.deleteAcademicYearPlanPopup=true
        },
        closeDeleteAcademicYearPopup:(state)=>{
            state.popup.deleteAcademicYearPlanPopup=false
        },

        // form reducer action
        addAcademicYearForm:(state, action)=>{
            state.form.academicYearForm=action.payload
        },
        addTermForm:(state, action)=>{
            state.form.termForm=action.payload
        },
        addTopicForm:(state, action)=>{
            state.form.topicForm=action.payload
        },
        addEditTermForm:(state, action)=>{
            state.form.editTermForm=action.payload
        },
        addEditTopicForm:(state, action)=>{
            state.form.editTopicForm=action.payload
        },
        addEditAcademicYearForm:(state, action)=>{
            state.form.editAcademicYearForm=action.payload
        },

        // other dispatch method
        addActiveTerm:(state, action)=>{
            state.other.activeTerm=action.payload
            sessionStorage.setItem('activeTerm', 
            JSON.stringify(state.other.activeTerm))
            const manageTerm = {
                title : action.payload?.title,
                description : action.payload?.description,
                start_date : action.payload?.start_date,
                end_date : action.payload?.end_date,
                icon : action.payload?.icon,
            }
            state.form.editTermForm=manageTerm
        },
        addActiveTopic:(state, action)=>{
            state.other.activeTopic=action.payload
            const manageTopic = {
                title : action.payload?.title,
                description : action.payload?.description,
                icon : action.payload.icon,
                standard_topic_id:action.payload.standard_topic_id,
            }
            state.form.editTopicForm=manageTopic
        },
        addAcademicPlan:(state, action)=>{
            state.other.academicPlan=action.payload
        },
        addTermList:(state, action)=>{
            state.other.termList=action.payload
        },
    }
})
export default academyPlanSlice.reducer

export const {openAcademicYearPopup, closeAcademicYearPopup, openSubtopicPopup, openTermPopup,
    openTopicPopup, closeSubtopicPopup, closeTermPopup, closeTopicPopup,addAcademicYearForm,
    addTermForm,addTopicForm, addActiveTerm, addActiveTopic, openEditTopicPopup, closeEditTopicPopup,
    addEditTermForm, openDeleteTopicPopup, closeDeleteTopicPopup, closeDeleteTermPopup,
    closeEditTermPopup, openDeleteTermPopup, openEditTermPopup, addEditTopicForm, openEditAcademicYearPopup,
    closeEditAcademicYearPopup, addEditAcademicYearForm, addAcademicPlan, openDeleteAcademicYearPopup,
    closeDeleteAcademicYearPopup, addTermList
}=academyPlanSlice.actions

// get state for popup
export const getAcademicYearPopup = (state) => state.academicPlan.popup.academicYearPopup
export const getTermPopup = (state) => state.academicPlan.popup.termPopup
export const getTopicPopup = (state) => state.academicPlan.popup.topicPopup
export const getSubtopicPopup = (state) => state.academicPlan.popup.subtopicPopup
export const getEditTopicPopup = (state) => state.academicPlan.popup.editTopicPopup
export const getEditTermPopup = (state) => state.academicPlan.popup.editTermPopup
export const getDeleteTopicPopup = (state) => state.academicPlan.popup.deleteTopicPopup
export const getDeleteTermPopup = (state) => state.academicPlan.popup.deleteTermPopup
export const getEditAcademicYearPopup = (state) => state.academicPlan.popup.editAcademicYearPopup
export const getDeleteAcademicYearPlanPopup = (state) => state.academicPlan.popup.deleteAcademicYearPlanPopup

// get state for form
export const getAcademicYearForm = (state) => state.academicPlan.form.academicYearForm
export const getTermForm = (state) => state.academicPlan.form.termForm
export const getTopicForm = (state) => state.academicPlan.form.topicForm
export const getEditTermForm = (state) => state.academicPlan.form.editTermForm
export const getEditTopicForm = (state) => state.academicPlan.form.editTopicForm
export const getEditAcademicYearForm = (state) => state.academicPlan.form.editAcademicYearForm

// get state for other 
export const getActiveTerm = (state) => state.academicPlan.other.activeTerm
export const getActiveTopic = (state) => state.academicPlan.other.activeTopic
export const getRootId = (state) => state.academicPlan.other.rootId
export const getAcademicPlan = (state) => state.academicPlan.other.academicPlan
export const getTermList = (state) => state.academicPlan.other.termList