// react library imports
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

// style imports
import './App.css';

// imports from routes
import { router } from './Routes/routes';

const App = () => {
  return(
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  ) 
}

export default App;