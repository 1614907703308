import { createSlice } from "@reduxjs/toolkit";

const classList= sessionStorage.getItem('classList') !== null ? 
JSON.parse(sessionStorage.getItem('classList')) : []
const activeClass= sessionStorage.getItem('activeClass') !== null ? 
JSON.parse(sessionStorage.getItem('activeClass')) : null

export const initialClassForm={
    program_information:"",
    class_name:"",
    minimum_age_of_admission:"",
    class_type:"",
    class_teacher:"",
    educators:[],
    special_teachers:[],
    cleaning_staff:"",
}

const classSlice = createSlice({
    name:"class",
    initialState:{
        classList:classList,
        activeClass:activeClass,
        classInformation:null,
        popup:{
            addNewClassPopup:false,
            documentPopup:false,
            changeRequestPopup:false,
            deleteClassPopup:false
        },
        form:{
            classForm:initialClassForm,
            editClassForm:initialClassForm
        }
    },
    reducers:{
        // class information reducer
        addClassList:(state, action)=>{
            state.classList=action.payload
            sessionStorage.setItem('classList', 
            JSON.stringify(state.classList))
        },
        addActiveClass:(state, action)=>{
            state.activeClass=action.payload
            sessionStorage.setItem('activeClass', 
            JSON.stringify(state.activeClass))
            const editClass ={
                class_name : action.payload?.class_name,
                class_teacher : action.payload?.class_teacher,
                class_type : action.payload?.class_type,
                educators : action.payload?.educators,
                helping_staff : action.payload?.helping_staff,
                special_teachers : action.payload?.special_teachers,
                program : action.payload?.program,
            }
            state.form.editClassForm=editClass
        },
        updateClassList:(state, action)=>{
            state.classList.push(action.payload)
        },
        addClassInformation:(state, action)=>{
            state.classInformation=action.payload
            const classObject={
                class_id:action.payload?.id,
                teacher_id:action.payload?.class_teacher?.id
            }
            
            const manageClassObject={
                class_name:action.payload?.class_name,
                teacher_id:action.payload?.class_teacher?.id,
                program_name:action.payload?.program,
                class_id:action.payload?.id,
            }
            state.form.changeTeacherForm=classObject
            state.form.manageClassForm=manageClassObject
        },

        // class popup reducer
        openAddNewClassPopup:(state)=>{
            state.popup.addNewClassPopup=true
        },
        closeAddNewClassPopup:(state)=>{
            state.popup.addNewClassPopup=false
            state.form.classForm=initialClassForm
        },
        openDeleteClassPopup:(state)=>{
            state.popup.deleteClassPopup=true
        },
        closeDeleteClassPopup:(state)=>{
            state.popup.deleteClassPopup=false
        },

        // class form reducer
        addClassForm:(state, action)=>{
            state.form.classForm=action.payload
        },
        addKidForm:(state, action)=>{
            state.form.kidForm=action.payload
        },
        editClassForm:(state, action)=>{
            state.form.editClassForm=action.payload
        },
    }
})

export const {addClassList, addActiveClass, closeAddNewClassPopup,
openAddNewClassPopup, addClassForm, updateClassList,addClassInformation,
closeDeleteClassPopup, openDeleteClassPopup, editClassForm}=classSlice.actions

// class information state
export const getClassList=(state)=>state.class.classList
export const getActiveClass=(state)=>state.class.activeClass
export const getClassInformation=(state)=>state.class.classInformation

// class popup  state
export const getAddNewClassPopup=(state)=>state.class.popup.addNewClassPopup
export const getDeleteClassPopup=(state)=>state.class.popup.deleteClassPopup

// class form state
export const getClassForm=(state)=>state.class.form.classForm
export const getUploadInBulkForm=(state)=>state.class.form.addUploadBulkInChildForm
export const getKidForm=(state)=>state.class.form.kidForm
export const getEditClassForm=(state)=>state.class.form.editClassForm

export default classSlice.reducer

