import { createSlice } from '@reduxjs/toolkit'

const annualPlannerActiveProgram= sessionStorage.getItem('annualPlannerActiveProgram') !== null ? 
JSON.parse(sessionStorage.getItem('annualPlannerActiveProgram')) : null
const activeThemeId= sessionStorage.getItem('activeThemeId') !== null ? 
JSON.parse(sessionStorage.getItem('activeThemeId')) : null

export const annualPlanner=createSlice({
    name:"annualPlanner",
    initialState: {
        annualPlannerActiveProgram:annualPlannerActiveProgram,
        activeThemeId:activeThemeId,
        unitOfExplorationInformation:null,
        activityInformation:null,
        curriculumId:null,
        popup:{
            addThemePopup:false,
            editThemePopup:false,
            addActivityPopup:false,
            editActivityPopup:false,
            deleteActivityPopup:false,
            addUnitOfExplorationPopup:false,
            editUnitOfExplorationPopup:false,
            deleteUnitOfExplorationPopup:false,
            deleteThemePopup:false,
        },
        form:{
            themeForm:{
                theme_name:'',
                description:'',
            },
            editThemeForm:{
                title:"",
                program_id:"", 
                start_date:"", 
                end_date:"", 
                description:"",
                theme_id:""
            },
            activityForm:{
                title:"",
                description:"",
                unit_of_exploration_id:"",
                slot:0,
                primary_skill_id:"",
                secondary_skill_id:"",
                primary_goal_id:"",
                stage_id:"",
                classes:[],
                points:0,
                duration:0,
                date:"",
                kid_points:0,
                icon:""
            },
            editActivityForm:{
                title:"",
                completion_point:"",
                description:"",
                duration:"",
                points:"",
                primary_skill:"",
                secondary_skill:"",
                activity_id:"",
                primary_goal_id:"",
                stage_id:"",
            },
            unitOfExplorationForm:{
                name:'',
                description:'',
                color:''
            },
            editUnitOfExplorationForm:{
                name:"",
                start_date:"",
                end_date:"",
                description:"",
                color:"",
                unit_of_exploration_id:"",
            },
            themeComponent:1,
            unitOfExplorationComponent:1,
            activityComponent:1
        }
    },
    reducers:{
        addAnnualPlannerActiveProgram:(state, action)=>{
            state.annualPlannerActiveProgram=action.payload
            sessionStorage.setItem('annualPlannerActiveProgram', 
            JSON.stringify(state.annualPlannerActiveProgram))
        },
        addCurriculumId:(state, action)=>{
            state.curriculumId=action.payload
        },
        addActiveThemeId:(state, action)=>{
            state.activeThemeId=action.payload
            sessionStorage.setItem('activeThemeId', 
            JSON.stringify(state.activeThemeId))
            const themeObject={
                title:action.payload?.title,
                theme_id:action.payload?.id,
                description:action.payload?.description,
                start_date:action.payload?.start_date,
                end_date:action.payload?.end_date,
                program_id:action.payload?.program_id,
            }
            state.form.editThemeForm=themeObject
        },
        addUnitOfExplorationInformation:(state, action)=>{
            state.unitOfExplorationInformation=action.payload
            const editUnitOfExplorationFormObject={
                name:action.payload?.name,
                start_date:action.payload?.start_date,
                end_date:action.payload?.end_date,
                description:action.payload?.description,
                color:action.payload?.color,
                unit_of_exploration_id:action.payload?.id,
            }
            state.form.editUnitOfExplorationForm=editUnitOfExplorationFormObject
        },
        addActivityInformation:(state, action)=>{
            state.activityInformation=action.payload
        },

        // annual planner popup reducer
        openThemePopup:(state)=>{
            state.popup.addThemePopup=true
        },
        closeThemePopup:(state)=>{
            state.popup.addThemePopup=false
        },
        openEditThemePopup:(state)=>{
            state.popup.editThemePopup=true
        },
        closeEditThemePopup:(state)=>{
            state.popup.editThemePopup=false
        },
        openAddActivityPopup:(state)=>{
            state.popup.addActivityPopup=true
        },
        closeAddActivityPopup:(state)=>{
            state.popup.addActivityPopup=false
        },
        openEditActivityPopup:(state)=>{
            state.popup.editActivityPopup=true
        },
        closeEditActivityPopup:(state)=>{
            state.popup.editActivityPopup=false
        },
        openDeleteActivityPopup:(state)=>{
            state.popup.deleteActivityPopup=true
        },
        closeDeleteActivityPopup:(state)=>{
            state.popup.deleteActivityPopup=false
        },
        openAddUnitOfExplorationPopup:(state)=>{
            state.popup.addUnitOfExplorationPopup=true
        },
        closeAddUnitOfExplorationPopup:(state)=>{
            state.popup.addUnitOfExplorationPopup=false
        },
        openEditUnitOfExplorationPopup:(state)=>{
            state.popup.editUnitOfExplorationPopup=true
        },
        closeEditUnitOfExplorationPopup:(state)=>{
            state.popup.editUnitOfExplorationPopup=false
        },
        openDeleteUnitOfExplorationPopup:(state)=>{
            state.popup.deleteUnitOfExplorationPopup=true
        },
        closeDeleteUnitOfExplorationPopup:(state)=>{
            state.popup.deleteUnitOfExplorationPopup=false
        },
        openDeleteThemePopup:(state)=>{
            state.popup.deleteThemePopup=true
        },
        closeDeleteThemePopup:(state)=>{
            state.popup.deleteThemePopup=false
        },

        //annual planner form reducer
        addThemeForm:(state,action)=>{
            state.form.themeForm=action.payload
        },
        addEditThemeForm:(state,action)=>{
            state.form.editThemeForm=action.payload
        },
        addActivityForm:(state,action)=>{
            state.form.activityForm=action.payload
        },
        addEditActivityForm:(state,action)=>{
            state.form.editActivityForm=action.payload
        },
        addThemeComponent:(state, action)=>{
            state.form.themeComponent=action.payload
        },
        addUnitOfExplorationComponent:(state, action)=>{
            state.form.unitOfExplorationComponent=action.payload
        },
        addActivityComponent:(state, action)=>{
            state.form.activityComponent=action.payload
        },
        addUnitOfExplorationForm:(state, action)=>{
            state.form.unitOfExplorationForm=action.payload
        },
        addEditUnitOfExplorationForm:(state, action)=>{
            state.form.editUnitOfExplorationForm=action.payload
        }
    }
})

export const {addAnnualPlannerActiveProgram, openEditThemePopup,
openThemePopup, closeEditThemePopup, closeThemePopup, 
addActiveThemeId, addThemeComponent, addThemeForm, addEditThemeForm,
openAddActivityPopup, closeAddActivityPopup, addActivityForm,
openAddUnitOfExplorationPopup, closeAddUnitOfExplorationPopup,
addUnitOfExplorationComponent, addUnitOfExplorationForm,
openEditUnitOfExplorationPopup, closeEditUnitOfExplorationPopup,
addUnitOfExplorationInformation, addEditUnitOfExplorationForm,
openDeleteUnitOfExplorationPopup, closeDeleteUnitOfExplorationPopup,
openEditActivityPopup, closeEditActivityPopup, addActivityComponent,
addActivityInformation, addEditActivityForm,closeDeleteActivityPopup,
openDeleteActivityPopup, openDeleteThemePopup, closeDeleteThemePopup, 
addCurriculumId}=annualPlanner.actions

// annual planner information state
export const getAnnualPlannerActiveProgram=(state)=>state.annualPlanner.annualPlannerActiveProgram
export const getActiveThemeId=(state)=>state.annualPlanner.activeThemeId
export const getUnitOfExplorationInformation=(state)=>state.annualPlanner.unitOfExplorationInformation
export const getActivityInformation=(state)=>state.annualPlanner.activityInformation
export const getCurriculumId=(state)=>state.annualPlanner.curriculumId

// annual planner popup state
export const getAddThemePopup=(state)=>state.annualPlanner.popup.addThemePopup
export const getEditThemePopup=(state)=>state.annualPlanner.popup.editThemePopup
export const getAddUnitOfExplorationPopup=(state)=>state.annualPlanner.popup.addUnitOfExplorationPopup
export const getAddActivityPopup=(state)=>state.annualPlanner.popup.addActivityPopup
export const getEditActivityPopup=(state)=>state.annualPlanner.popup.editActivityPopup
export const getEditUnitOfExplorationPopup=(state)=>state.annualPlanner.popup.editUnitOfExplorationPopup
export const getDeleteUnitOfExplorationPopup=(state)=>state.annualPlanner.popup.deleteUnitOfExplorationPopup
export const getDeleteActivityPopup=(state)=>state.annualPlanner.popup.deleteActivityPopup
export const getDeleteThemePopup=(state)=>state.annualPlanner.popup.deleteThemePopup

// annual planner form state
export const getThemeForm=(state)=>state.annualPlanner.form.themeForm
export const getThemeComponent=(state)=>state.annualPlanner.form.themeComponent
export const getEditThemeForm=(state)=>state.annualPlanner.form.editThemeForm
export const getActivityForm=(state)=>state.annualPlanner.form.activityForm
export const getEditActivityForm=(state)=>state.annualPlanner.form.editActivityForm
export const getUnitOfExplorationForm=(state)=>state.annualPlanner.form.unitOfExplorationForm
export const getUnitOfExplorationComponent=(state)=>state.annualPlanner.form.unitOfExplorationComponent
export const getEditUnitOfExplorationForm=(state)=>state.annualPlanner.form.editUnitOfExplorationForm
export const getActivityComponent=(state)=>state.annualPlanner.form.activityComponent

export default annualPlanner.reducer