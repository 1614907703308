// react library imports
import { lazy } from 'react';

// Layout imports
const HelpCenterLayout = lazy(()=>import('Layout/HelpCenter'))

// help center pages
const UserGuidelines = lazy(()=>import('Pages/HelpCenter/UserGuidelines/UserGuidelines'))
const SiteMap = lazy(()=>import('Pages/HelpCenter/SiteMap/SiteMap'))
const RefundPolicy = lazy(()=>import('Pages/HelpCenter/RefundPolicy/RefundPolicy'))
const TakedownPolicy = lazy(()=>import('Pages/HelpCenter/TakedownPolicy/TakedownPolicy'))
const GrievanceRedressal = lazy(()=>import('Pages/HelpCenter/GrievanceRedressal/GrievanceRedressal'))
const MuesPro = lazy(()=>import('Pages/HelpCenter/MuesPro/MuesPro'))

export const helpCenterRoutes = [
    {
      path: "help-center",
      element: <HelpCenterLayout />,
      children: [
        { path: "user-guidelines", element: <UserGuidelines /> },
        { path: "site-map", element: <SiteMap /> },
        { path: "refund-policy", element: <RefundPolicy /> },
        { path: "takedown-policy", element: <TakedownPolicy /> },
        { path: "grievance-redressal", element: <GrievanceRedressal /> },
        { path: "mues-pro", element: <MuesPro /> },
      ],
    },
  ];