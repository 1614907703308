import { createSlice } from '@reduxjs/toolkit'

export const popup=createSlice({
    name:"popup",
    initialState: {
        created:false,
        deleted:false,
        updated:false,
        schedule:false,
        downloadPopup:false,
        navigationPopup:false,
        navigationName:null
    },
    reducers:{
        openCreated:(state)=>{
            state.created=true
        },
        closeCreated:(state)=>{
            state.created=false
        },
        openDeleted:(state)=>{
            state.deleted=true
        },
        closeDeleted:(state)=>{
            state.deleted=false
        },
        openUpdated:(state)=>{
            state.updated=true
        },
        closeUpdated:(state)=>{
            state.updated=false
        },
        openDownloadPopup:(state, action)=>{
            state.downloadPopup=true
            state.navigationName=action.payload
        },
        closeDownloadPopup:(state)=>{
            state.downloadPopup=false
            state.navigationName=null
        },
        openSchedule:(state)=>{
            state.schedule=true
        },
        closeSchedule:(state)=>{
            state.schedule=false
        },
        openNavigationPopup:(state,action)=>{
            state.navigationPopup=true
            state.navigationName=action.payload
        },
        closeNavigationPopup:(state)=>{
            state.navigationPopup=false
            state.navigationName=null
        }
    }
})

export const {openCreated, openDeleted, openUpdated,
closeCreated, closeDeleted, closeUpdated, 
openDownloadPopup, closeDownloadPopup, openSchedule,
closeSchedule, openNavigationPopup, closeNavigationPopup}=popup.actions

// popup state
export const getCreated=(state)=>state.popup.created
export const getUpdated=(state)=>state.popup.updated
export const getDeleted=(state)=>state.popup.deleted
export const getSchedule=(state)=>state.popup.schedule
export const getDownloadPopup=(state)=>state.popup.downloadPopup
export const getNavigationPopup=(state)=>state.popup.navigationPopup
export const getNavigationName=(state)=>state.popup.navigationName

export default popup.reducer