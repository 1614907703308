import { createSlice } from "@reduxjs/toolkit";

const userDeleteInformation= sessionStorage.getItem('userDeleteInformation') !== null ? 
JSON.parse(sessionStorage.getItem('userDeleteInformation')) : null
const accountComponent= sessionStorage.getItem('accountComponent') !== null ? 
JSON.parse(sessionStorage.getItem('accountComponent')) : 1

const initialState={
    userDeleteInformation:userDeleteInformation,
    accountComponent:accountComponent,
    form:{
        deleteAccountForm:{
            mobile_number:""
        }
    }
}

const accountDelete=createSlice({
    name:'accountDelete',
    initialState,
    reducers:{
        addUserDeleteInformation:(state, action)=>{
            state.userDeleteInformation=action.payload
            sessionStorage.setItem('userDeleteInformation', 
            JSON.stringify(state.userDeleteInformation))
        },
        
        addDeleteAccountDeleteForm:(state, action)=>{
            state.form.deleteAccountForm=action.payload
        },
        addAccountComponent:(state, action)=>{
            state.accountComponent=action.payload
            sessionStorage.setItem('accountComponent', 
            JSON.stringify(state.accountComponent))
        }
    }
})

export const {addDeleteAccountDeleteForm, addUserDeleteInformation, 
addAccountComponent}=accountDelete.actions

//preschool information state
export const getDeleteUserInformation=(state)=>state.accountDelete.userDeleteInformation
export const getAccountComponent=(state)=>state.accountDelete.accountComponent

//preschool form state
export const getDeleteAccountForm=(state)=>state.accountDelete.form.deleteAccountForm

export default accountDelete.reducer