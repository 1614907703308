import { createSlice } from "@reduxjs/toolkit";

const preschoolInformation= localStorage.getItem('preschoolInformation') !== null ? 
JSON.parse(localStorage.getItem('preschoolInformation')) : null

const yearList= sessionStorage.getItem('yearList') !== null ? 
JSON.parse(sessionStorage.getItem('yearList')) : []

const notification= sessionStorage.getItem('notification') !== null ? 
JSON.parse(sessionStorage.getItem('notification')) : null

const initialState={
    preschoolList:[],
    preschoolInformation:preschoolInformation,
    yearList:yearList,
    notification:notification,
    form:{
        preschoolForm:{
            preschool_name:"",
            address:"",
            city:"",
            state:"",
            pincode:"",
            email_id:"",
            preschool_director:""
        },
        editPreschoolForm:{
            name:"",
            legal_entity_name:"",
            type_of_preschool:"",
            pincode:"",
            street_name:"",
            city:"",
            state:""
        },
        accountForm:{
            account_holder_name:"",
            account_number:"",
            conform_account_number:"",
            ifsc_code:"",
            bank:"",
        }
    },
    popup:{
        deletePreschoolPopup:false
    }
}

const preschoolSlice=createSlice({
    name:'preschool',
    initialState,
    reducers:{
        addPreschoolList:(state, action)=>{
            state.preschoolList=action.payload
        },
        addPreschoolInformation:(state, action)=>{
            state.preschoolInformation=action.payload
            localStorage.setItem('preschoolInformation', 
            JSON.stringify(state.preschoolInformation))
        },
        addYearList:(state, action)=>{
            state.yearList=action.payload
            sessionStorage.setItem('yearList', 
            JSON.stringify(state.yearList))
        },
        addNotification:(state, action)=>{
            state.notification=action.payload
            sessionStorage.setItem('notification', 
            JSON.stringify(state.notification))
        },

        //preschool form reducer
        addPreschoolForm:(state,action)=>{
            state.form.preschoolForm=action.payload
        },
        editPreschoolForm:(state,action)=>{
            state.form.editPreschoolForm=action.payload
        },
        addAccountForm:(state, action)=>{
            state.form.accountForm=action.payload
        },

        // popup reducer
        openDeletePreschoolPopup:(state)=>{
            state.popup.deletePreschoolPopup=true
        },
        closeDeletePreschoolPopup:(state)=>{
            state.popup.deletePreschoolPopup=false
        }

    }
})

export const {addPreschoolList, addPreschoolInformation, 
    addYearList, addPreschoolForm, addNotification,openDeletePreschoolPopup,
    closeDeletePreschoolPopup,editPreschoolForm, addAccountForm}=preschoolSlice.actions

//preschool information state
export const getPreschoolList=(state)=>state.preschool.preschoolList
export const getPreschoolInformation=(state)=>state.preschool.preschoolInformation
export const getPreschoolYearList=(state)=>state.preschool.yearList
export const getPreschoolNotification=(state)=>state.preschool.notification
export const getEditPreschoolForm=(state)=>state.preschool.form.editPreschoolForm

//preschool form state
export const getPreschoolForm=(state)=>state.preschool.form.preschoolForm
export const getAccountForm=(state)=>state.preschool.form.accountForm

// popup state
export const getDeletePreschoolPopup=(state)=>state.preschool.popup.deletePreschoolPopup

export default preschoolSlice.reducer