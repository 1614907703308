// react library imports
import { lazy } from 'react';

// Layout imports
const PoliciesLayout = lazy(()=>import('Layout/Policies'))

// policies pages
const Academy = lazy(()=>import('Pages/PrivacyPolicy/Academy/Academy'))
const Parent = lazy(()=>import('Pages/PrivacyPolicy/Parent/Parent'))
const TeacherPolicies = lazy(()=>import('Pages/PrivacyPolicy/Teacher/Teacher'))
const PlayAR = lazy(()=>import('Pages/PrivacyPolicy/PlayAR/PlayAR'))
const AccountDelete = lazy(()=>import('Pages/PrivacyPolicy/Academy/AccountDelete'))
const MuesTech = lazy(()=>import('Pages/PrivacyPolicy/MuesTech/MuesTech'))
const TermsAndCondition = lazy(()=>import('Pages/PrivacyPolicy/TermsAndCondition/TermsAndCondition'))

export const policiesRoutes = [
    {
      path: "privacy-policy",
      element: <PoliciesLayout />,
      children: [
        { index: true, element: <MuesTech /> },
        { path: 'mues-parent', element: <Parent /> },
        { path: "mues-teacher", element: <TeacherPolicies /> },
        { path: "mues-playar", element: <PlayAR /> },
        { path: "mues-academy", element: <Academy /> },
        { path: "delete-account", element: <AccountDelete /> },
      ],
    },
    {
      path: "terms-and-condition",
      element: <PoliciesLayout />,
      children: [
        { index: true, element: <TermsAndCondition /> },
      ],
    },
  ];