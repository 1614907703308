// react library imports
import { lazy } from 'react';

// Layout imports
const PublicLayout = lazy(()=>import('Layout/Public'))

// public pages imports
const LandingPage = lazy(()=>import('Pages/Public/LandingPage/LandingPage'))
const About = lazy(()=>import('Pages/Public/About/About'))
const Philosophy = lazy(()=>import('Pages/Public/Philosophy/Philosophy'))
const Careers = lazy(()=>import('Pages/Public/Careers/Careers'))
const Preschool = lazy(()=>import('Pages/Public/Preschool/Preschool'))
const TeacherPage = lazy(()=>import('Pages/Public/Teacher/Teacher'))
const Parents = lazy(()=>import('Pages/Public/Parents/Parents'))
const Resource = lazy(()=>import('Pages/Public/Resource/Resource'))
const Blog = lazy(()=>import('Pages/Public/Blog/Blog'))
const BlogPost = lazy(()=>import('Pages/Public/Blog/Component/BlogPost/BlogPost'))
const AcademyPage = lazy(()=>import('Pages/Public/Academy/Academy'))
const Contact = lazy(()=>import('Pages/Public/Contact/Contact'))
const Community = lazy(()=>import('Pages/Public/Community/Community'))

export const publicRoutes = [
    {
      path: "/",
      element: <PublicLayout />,
      children: [
        { index: true, element: <LandingPage /> },
        { path: "about", element: <About /> },
        { path: "philosophy", element: <Philosophy /> },
        { path: "career", element: <Careers /> },
        { path: "teacher", element: <TeacherPage /> },
        { path: "parent", element: <Parents /> },
        { path: "resource", element: <Resource /> },
        { path: "preschool", element: <Preschool /> },
        { path: "blog", element: <Blog /> },
        { path: "blog/:id", element: <BlogPost /> },
        { path: "mues-academy", element: <AcademyPage /> },
        { path: "community", element: <Community /> },
        { path: "contact", element: <Contact /> },
      ],
    },
];